.footerContainer {
  /* height: 50px; */
  position: fixed;
  height: 50px;
  background-color: red;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  color: white;

  bottom: 0;
  right: 0;
  left: 0;
  /* padding: 10px; */
  /* padding: 1rem; */
  text-align: center;
  /* margin-top: 5px; */
  background-color: #282c34;
}

p.footerText {
  /* margin: 50px; */
  /* padding: 10px; */
}

a.mailLink:link {
  text-decoration: none;
  color: white;
}
