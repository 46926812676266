.navigation {
  /* position: -webkit-sticky; */
  position: fixed;
  top: 50px;
  /* padding: 5px; */
  /* background-color: #cae8ca;
  border: 2px solid #4CAF50; */
  /* display: inline-block; */
  /* z-index: 100; */
  width: 50px;
  height: 50px;
  padding: 12px 50px;
  /* background-color: blue; */
  position: fixed;
  /* top: 40px; */
  left: 0;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.dropdown-content {
  /* animation-timing-function: ease; */
  display: block;
  font-family: slideOut;
  /* animation: out 2s 1;
  position: absolute; */

  position: fixed;
  top: 90px;
  left: -275px;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background-color: #554747;
  background-image: url('../assests/backgrounds/PNG_Files/005-wood-grain.png');
  min-width: 160px;
  /* height: 100px; */
  /* -webkit-filter: drop-shadow(1px 1px 0 black)
                drop-shadow(-1px -1px 0 white)
                drop-shadow(50px 30px 4px #000000);
  filter:drop-shadow(1px 1px 0 black)
         drop-shadow(-1px -1px 0 white)
         drop-shadow(50px 30px 4px #000000);
  padding: 12px 16px; */
  z-index: 100;
}

.navigation  :hover {
  left: 250px
}

.navigation:hover .dropdown-content {
  height: auto;
  left: 0;
  -webkit-filter: drop-shadow(1px 1px 0 black)
                /* drop-shadow(-1px -1px 0 white) */
                drop-shadow(50px 30px 4px #000000);
  filter:drop-shadow(1px 1px 0 black)
         /* drop-shadow(-1px -1px 0 white) */
         drop-shadow(50px 30px 4px #000000);
}

div.navigation2 {

    position: relative;
    top: 3vh;
    min-width: 75px;
    margin-left: 75px;
    padding: 105px;
    background-color: #cae8ca;
    border: 2px solid #4CAF50;
    z-index:1;
}

.TWW_logo_menu {
  /* position: -webkit-sticky; */
  position: fixed;
  top: 0;
  /* left: 0; */
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  /* height: 60vmin; */
  pointer-events: initial;
  -webkit-filter: drop-shadow(1px 1px 0 black)
                drop-shadow(-1px -1px 0 white)
                drop-shadow(50px 30px 4px #000000);
  filter:drop-shadow(1px 1px 0 black)
         drop-shadow(-1px -1px 0 white)
         drop-shadow(50px 30px 4px #000000);
  z-index: -10;
}

.nav-item {
  background-image: url('../assests/backgrounds/PNG_Files/004-polished-wood.png');
  background-color: #554747;
  border-radius: 10px;
  border: 2px solid #73AD21;
  list-style-type: none;
  margin: 25px;
  padding: 20px 10px;
}

#menuTab {
  font-family: 'slideOut';
  /* background-color: #554747; */
  background-image: url('../assests/backgrounds/PNG_Files/004-polished-wood.png');
  margin-left: 100%;
  transform: rotate(90deg);
  /* border: 2px solid pink; */
  height:20px;
  width:50px;
  /* margin: 25px; */
  padding: 20px 0px 10px 0px;
  border-radius: 10px 10px 00px 0px;
  text-transform: uppercase;
  text-align: center;
  z-index: -10;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-top-color: #4CAF50;
  border-right-color: #4CAF50;
  border-left-color: #4CAF50;
}
