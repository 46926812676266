.App {
  /* text-align: center; */
}

body {
  /* opacity: 0.5; */
  background-color: #282c34;
  background-image: url('./assests/backgrounds/PNG_Files/tileable-wood-colored.png');
  /* width: 100vw;
  height: 100vh */
}

a {
    text-decoration: none;
}

a:link {    /* unvisited link */
    color: #000000;
    /* text-decoration: none; */
    /* border-bottom: 1px solid; */
}
a:visited {    /* visited link */
    color: #000000;
    /* text-decoration: none; */
}
a:hover {    /* mouse over link */
    color: #00ff00;
    border-bottom: 1px solid;
    text-decoration: none;

}
a:active {    /* active link */
    color: #000000;
}

@media (prefers-reduced-motion: no-preference) {
  .TWW_logo_main {


    /* animation: TWW_logo_main-spin infinite 30s linear; */
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.App-link {
  color: #61dafb;
}

@keyframes TWW_logo_main-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
    font-family: 'slideOut'; /*a name to be used later*/
    src: url('./assests/fonts/ShareTechMono-Regular.ttf'); /*URL to font*/
}
