.working {
  color: white;
  text-align: center;
  margin: auto;

}

.TWW_logo_main {
  padding-bottom: 0px ;
  margin: -150px auto;
  z-index: -5;
  height: 90vmin;
  pointer-events: none;

  -webkit-filter: drop-shadow(1px 1px 0 black)
                drop-shadow(-1px -1px 0 white)
                drop-shadow(50px 30px 4px #000000);
  filter:drop-shadow(1px 1px 0 black)
         drop-shadow(-1px -1px 0 white)
         drop-shadow(50px 30px 4px #000000);

}

.announcement {
/* margin-bottom: 150px */
  }

  /* On screens that are 600px wide or less, make the menu links stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .TWW_logo_main {
      padding-bottom: 0px ;
      margin: auto;
      z-index: -5;
      height: 90vmin;
      pointer-events: none;

      -webkit-filter: drop-shadow(1px 1px 0 black)
                    drop-shadow(-1px -1px 0 white)
                    drop-shadow(50px 30px 4px #000000);
      filter:drop-shadow(1px 1px 0 black)
             drop-shadow(-1px -1px 0 white)
             drop-shadow(50px 30px 4px #000000);

    }
  }
