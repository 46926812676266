form {
  width: 100%;
  max-width: 500px;
  margin: 50px auto;
  min-height: 250px;
  padding: 40px;
}

form input {
  width: 100%;
  height: 28px;
  margin-bottom: 15px;
  padding: 5px;
}

form textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  padding: 5px;
}

input[type="submit"] {
    width: 100%;

    appearance: auto;
    user-select: none;
    white-space: pre;
    /* align-items: flex-start; */
    /* text-align: center; */
    cursor: pointer;
    box-sizing: border-box;
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
    color: -internal-light-dark(black, white);
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}
