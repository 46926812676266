div.pageContainer {
  position: relative;

  margin: 20vh auto;
  background-color: #ffffff;
  border: 1px solid black;
  opacity: 0.6;
  z-index: -1;
}

div.pageContainer h1, h2, h3, p.tipsText {
  /* background-color: white; */
  /* position: relative; */
  /* font-weight: bold; */
  color: #000000;
  opacity: 1;
  /* z-index: -10; */
  margin: 10px;
  /* z-index: 50; */

}

p {

  padding-bottom: 15px
}

li {
  margin: 10px;
  padding-bottom: 15px
}
